import type { IncomingMessage } from 'http'
import Router from 'next/router'

/** 此方法会跳转到 redirect 参数所在的位置 */
export const gotoRedirect = () => {
  setTimeout(() => {
    const { query } = Router
    const { redirect } = query as { redirect: string }
    Router.push(redirect || `/${localStorage.getItem('username')}`)
  }, 10)
}

export function isRegister() {
  return Router.pathname === '/user/register'
}

export function isInvitePage() {
  return Router.pathname.startsWith('/invite')
}

export function apiHost(): string {
  if (process.env.NEXT_PUBLIC_API) {
    return process.env.NEXT_PUBLIC_API
  }
  if (typeof window === 'undefined') {
    return 'http://undefined'
  }
  return `${window.location.protocol}//${window.location.hostname}${process.env.NEXT_PUBLIC_API_PORT}`
}

export function wsHost(): string {
  if (process.env.NEXT_PUBLIC_WS_HOST) {
    return process.env.NEXT_PUBLIC_WS_HOST
  }
  return 'wss://gapi.gd.lingti.com'
}

export function apiURL(rURL: string): string {
  if (process.env.NEXT_PUBLIC_API) {
    return `${process.env.NEXT_PUBLIC_API}${rURL}`
  }
  if (process.env.NODE_ENV === 'test') {
    return `http://localhost:3000${rURL}`
  }
  return rURL
}

export function wsURL(reqPath?: string) {
  return `${wsHost()}/${reqPath ?? 'ws'}`
}

export function apiUrl(req?: IncomingMessage, localhostAddress = 'localhost:3000') {
  if (process.env.NEXT_PUBLIC_API) {
    return new URL(process.env.NEXT_PUBLIC_API)
  }
  let host = (req?.headers ? req.headers.host : window.location.host) || localhostAddress
  let protocol = /^localhost(:\d+)?$/.test(host) ? 'http:' : 'https:'

  if (req && req.headers['x-forwarded-host'] && typeof req.headers['x-forwarded-host'] === 'string') {
    host = req.headers['x-forwarded-host']
  }

  if (req && req.headers['x-forwarded-proto'] && typeof req.headers['x-forwarded-proto'] === 'string') {
    protocol = `${req.headers['x-forwarded-proto']}:`
  }

  return {
    protocol,
    host,
    origin: `${protocol}//${host}`,
  }
}
