import { message } from 'antd'
import { NoticeType } from 'antd/es/message/interface'
import _ from 'lodash'

// TODO: 这里的clickable语意好像反了
let clickable = false
let enable = true

const alphanumList = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
export function randomAlphanums(len: number): string {
  return _.join(_.sampleSize(alphanumList, len), '')
}

export const waitTime = (time = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })
}

export const debouncedMessage = (text: string, level: NoticeType = 'success') => {
  if (!clickable) {
    message[level](text)
    clickable = true
    setTimeout(() => {
      clickable = false
    }, 3500)
  }
}

export const debouncedFunction = (fn: () => void) => {
  if (enable) {
    fn()
    enable = false
    setTimeout(() => {
      enable = true
    }, 2000)
  }
}

export const debouncedMoveFunction = (fn: () => void) => {
  if (enable) {
    fn()
    enable = false
    setTimeout(() => {
      enable = true
    }, 100)
  }
}
