import { post } from './request'

export const getMobileLoginCode = async (mobile, captcha) => {
  const res = await post('users/verifications/send_mobile_login_code', {
    mobile,
    ...captcha,
  })
  return res
}

export const getMobileSignCode = async (mobile, captcha) => {
  const res = await post('users/verifications/send_mobile_registeration_code', {
    mobile,
    ...captcha,
  })
  return res
}

export const sendFindPasswordCode = async (emailOrMobile, captcha) => {
  const res = await post('users/passwords/send_find_password_code', {
    email_or_mobile: emailOrMobile,
    ...captcha,
  })
  return res
}

export const tencentCaptchaVerify = (afterVerify) => {
  const appId = '2045866641'
  // eslint-disable-next-line
  // @ts-ignore
  const captcha = new TencentCaptcha(appId, (res) => {
    if (res.ret === 0) {
      afterVerify(res)
    }
  })
  captcha.show()
}

export const sendBindMobileCode = async (mobile, captcha) => {
  const res = await post('users/verifications/send_mobile_code', {
    mobile,
    ...captcha,
  })
  return res
}
