import Image from 'next/image'
import { Layout } from 'antd'
import BgCuratin from '@/public/bg-curtain.svg'
import BgBase from '@/public/bg-base.svg'

const SimpleBackground = () => {
  return (
    <Layout className="fixed inset-0 h-full" hasSider>
      <Layout.Content className="h-full w-full transition-all duration-300">
        <Image src={BgCuratin} className="fixed top-16 z-10 h-auto w-full" alt="" />
        <Image src={BgBase} className="z-5 fixed w-full object-cover" alt="" fill />
      </Layout.Content>
    </Layout>
  )
}

export default SimpleBackground
