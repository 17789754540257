import React, { useState, useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'
import { Form, Input, Card, QRCode, Divider } from 'antd'
import { clearToken, post } from '@/utils/request'
import { getMobileLoginCode, tencentCaptchaVerify } from '@/utils/self'
import SimpleBackground from '@/components/SimpleBackground'
import Image from 'next/image'
import { useChannel } from '@/hooks/useWebSocket'
import useLogin from '@/hooks/swr/useLogin'
import useAdmin from '@/hooks/swr/useAdmin'
import useAuthUUID from '@/hooks/swr/useWeixinMini'
import IconFont from '@/components/IconFonts'
import Button from '@/components/Button'
import { isWechat } from '@/utils'
import { debouncedMessage } from '@/utils/debouncedFunction'

const Login = ({ modifiedPostLogin }) => {
  const router = useRouter()
  const [formRef] = Form.useForm()
  const [mobileCodeSent, setMobileCodeSent] = useState(false)
  const [loginBy, setLoginBy] = useState('mobile')
  const [countDown, setCountDown] = useState(60)
  const [btnText, setBtnText] = useState('发送验证码')
  const [count, setCount] = useState(300)
  const [expired, setExpired] = useState(false)
  const { isLogin, mutate: mutateLogin } = useLogin()
  const { mutate: mutateAdmin } = useAdmin()
  const { auth_uuid: uuid, mutate: mutateAuthUUID } = useAuthUUID()

  const postLogin =
    modifiedPostLogin ||
    (async (params, captcha) => {
      const res = await post('users/sign_in', { ...params, ...captcha })
      return res
    })
  const login = async (params, captcha?: any) => {
    clearToken()
    const res = await postLogin(params, captcha)
    if (res.id) {
      await mutateLogin((data) => ({ ...data, is_login: undefined }))
      await mutateAdmin((data) => ({ ...data, is_admin: undefined }))
      router.push((router.query.redirect as string) ?? '/user')
    } else {
      debouncedMessage(res.error, 'error')
    }
  }

  useEffect(() => {
    if (isLogin) {
      router.push((router.query.redirect as string) ?? '/user')
    }
  }, [isLogin, router])

  useEffect(() => {
    if (mobileCodeSent === true && countDown > 0) {
      setTimeout(() => setCountDown(countDown - 1), 1000)
      setBtnText(`${countDown} s`)
    } else if (countDown <= 0) {
      setBtnText('重新发送')
      setMobileCodeSent(false)
      setCountDown(60)
    }
  }, [countDown, mobileCodeSent])

  useChannel(`login-${uuid}`, true, (obj) => {
    switch (obj.action) {
      case 'loginByMini':
        if (obj.data.token) {
          localStorage.setItem('Token', `Bearer ${obj.data.token}`)
          window.location.href = (router.query.redirect as string) ?? '/'
        }
        break
      default:
    }
  })

  useEffect(() => {
    if (uuid && uuid !== '' && count > 0) {
      setTimeout(() => setCount(count - 1), 1000)
    } else if (count <= 0) {
      setExpired(true)
      setCount(300)
    }
  }, [count, uuid])

  const loginByWxCode = useCallback(
    async (c) => {
      const res = await post('users/sign_in', { login_code: c })
      if (res.id) {
        await mutateLogin((data) => ({ ...data, is_login: undefined }))
        await mutateAdmin((data) => ({ ...data, is_admin: undefined }))
        router.push((router.query.redirect as string) ?? '/user')
      }
    },
    [mutateAdmin, mutateLogin, router]
  )

  useEffect(() => {
    const params = new URLSearchParams(document.location.search.substring(1))
    const code = params.get('code')
    if (isWechat())
      if (code) {
        loginByWxCode(code)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <SimpleBackground />
      <div className="mx-auto mt-24 w-96 max-w-full rounded-lg p-2 pb-12 shadow">
        <Card className="border-none bg-transparent">
          <div className="text-center">
            <Image src="/lingti-gd-logo.png" alt="" width={96} height={96} className="relative m-auto mb-3" />
          </div>
          {loginBy === 'qrcode' ? (
            <div className="hidden md:block">
              <div className="relative text-center">
                <QRCode
                  className="m-auto my-4 rounded-none border border-solid border-gray-300"
                  bordered={false}
                  iconSize={60}
                  value={`${window.location.origin}/login?id=${uuid}`}
                  bgColor="white"
                  status={expired ? 'expired' : 'active'}
                  onRefresh={() => mutateAuthUUID().then(() => setExpired(false))}
                  size={180}
                />
              </div>
              <div className="my-2 text-center text-base text-white">打开微信扫一扫登录/注册</div>
            </div>
          ) : (
            <div>
              <div className="my-6 flex justify-around">
                <div
                  role="presentation"
                  onClick={() => setLoginBy('mobile')}
                  className={`${loginBy === 'mobile' ? 'font-bold' : 'opacity-70'} cursor-pointer pb-1 text-base text-white`}
                  style={loginBy === 'mobile' ? { borderBottom: '2px solid #fff' } : {}}
                >
                  快捷登录
                </div>
                <div
                  role="presentation"
                  onClick={() => setLoginBy('password')}
                  className={`${loginBy === 'password' ? 'font-bold' : 'opacity-70'} cursor-pointer pb-1 text-base text-white`}
                  style={loginBy === 'password' ? { borderBottom: '2px solid #fff' } : {}}
                >
                  密码登录
                </div>
              </div>
              {loginBy === 'password' ? (
                <Form
                  form={formRef}
                  onFinish={(values) => {
                    tencentCaptchaVerify((res) => login({ email_or_mobile: values.email_or_mobile, password: values.password }, { ticket: res.ticket, randstr: res.randstr }))
                  }}
                >
                  <Form.Item name="email_or_mobile" rules={[{ message: '请输入账号', required: true }]}>
                    <Input placeholder="账号" className="h-10 w-full" />
                  </Form.Item>
                  <Form.Item name="password" rules={[{ message: '请输入密码', required: true }]}>
                    <Input
                      type="password"
                      placeholder="密码"
                      className="h-10 w-full p-4"
                      onPressEnter={() =>
                        tencentCaptchaVerify((res) =>
                          login(
                            { email_or_mobile: formRef.getFieldValue('email_or_mobile'), password: formRef.getFieldValue('password') },
                            { ticket: res.ticket, randstr: res.randstr }
                          )
                        )
                      }
                    />
                  </Form.Item>
                  <div className="float-right -mt-16 flex h-10 items-center justify-center">
                    <a role="presentation" className="z-50 cursor-pointer p-0 px-4 hover:opacity-50" onClick={() => router.push('/reset_password')}>
                      忘记密码
                    </a>
                  </div>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="h-10 w-full">
                      立即登录
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <Form
                  form={formRef}
                  onFinish={(values) => {
                    login({ email_or_mobile: values.email_or_mobile, code: values.code })
                  }}
                >
                  <Form.Item name="email_or_mobile" rules={[{ message: '请输入手机号', required: true }]}>
                    <Input autoFocus placeholder="手机号" className="h-10 w-full" />
                  </Form.Item>
                  <Form.Item name="code" rules={[{ message: '请输入验证码', required: true }]}>
                    <Input
                      placeholder="验证码"
                      className="h-10 w-full"
                      onPressEnter={() => login({ email_or_mobile: formRef.getFieldValue('email_or_mobile'), code: formRef.getFieldValue('code') })}
                    />
                  </Form.Item>
                  <div className="float-right -mt-16 flex h-10 items-center justify-center">
                    <Button
                      type="text"
                      role="presentation"
                      className="z-50 p-0 px-4 hover:bg-transparent"
                      disabled={countDown < 60}
                      onClick={() => {
                        tencentCaptchaVerify((captcha) =>
                          getMobileLoginCode(formRef.getFieldValue('email_or_mobile'), captcha).then((res) => {
                            if (res.ok) {
                              setMobileCodeSent(true)
                              debouncedMessage(res.message)
                            } else {
                              debouncedMessage(res.message, 'warning')
                            }
                          })
                        )
                      }}
                    >
                      <a className={`${countDown < 60 ? 'cursor-not-allowed' : 'cursor-pointer'}`}>{btnText}</a>
                    </Button>
                  </div>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="h-10 w-full">
                      登录/注册
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </div>
          )}
        </Card>
        <Card className="divider-bg bg-transparent text-sm shadow-none" bordered={false} styles={{ body: { padding: 0 } }}>
          <Divider className="hidden px-6 text-sm text-white md:flex">其他方式</Divider>
          <div className="mb-6 hidden flex-col items-center justify-center text-white md:flex">
            <IconFont
              icon={`${loginBy === 'qrcode' ? 'icon-mobile' : 'icon-wechat'}`}
              className="my-2 flex h-12 w-12 cursor-pointer items-center justify-center rounded-md bg-gray-200/[0.5] text-3xl hover:bg-gray-300/[0.4]"
              onClick={() => {
                if (loginBy !== 'qrcode') {
                  mutateAuthUUID().then(() => {
                    setCount(300)
                    setExpired(false)
                  })
                }
                setLoginBy(loginBy === 'qrcode' ? 'mobile' : 'qrcode')
              }}
            />
            {loginBy === 'qrcode' ? '手机号登录' : '微信快捷登录'}
          </div>
          <div className="text-center text-xs text-gray-300 ">
            登录即表示已阅读并同意
            <a href="https://xiemala.com/s/zZDQd9" target="_blank" rel="noreferrer" className="text-white hover:opacity-60">
              《用户协议》
            </a>
            和
            <a href="https://xiemala.com/s/-_BdW4" target="_blank" rel="noreferrer" className="text-white hover:opacity-60">
              《隐私政策》
            </a>
          </div>
          {isWechat() && (
            <div className="mt-6 text-center">
              <a
                href={`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd532f5828df74eae&redirect_uri=https://gd.lingti.com/login?redirect=${
                  router.query.redirect ?? 'user'
                }&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`}
                target="_blank"
                rel="noreferrer"
              >
                <Image src="/login/wechat.png" width={50} height={50} alt="" />
              </a>
              <div className="mt-2 text-xs text-white">微信登录</div>
            </div>
          )}
        </Card>
      </div>
    </div>
  )
}

export default Login
