import { get } from '@/utils/request'
import useSWR from 'swr'
import { commonSWROptions } from './useAdmin'

const getAuthUUID = () =>
  get('/users/verifications/generate_auth_uuid').then((res) => {
    return res
  })

const useAuthUUID = () => {
  const { data, mutate } = useSWR(`auth_uuid`, getAuthUUID, commonSWROptions)
  return { auth_uuid: data?.id, mutate }
}

export default useAuthUUID
