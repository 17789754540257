/* eslint-disable no-underscore-dangle */
import Cookies from 'js-cookie'
import isEmpty from 'lodash-es/isEmpty'

import { isBrowser } from '@/utils/utils'

import { randomAlphanums } from './debouncedFunction'

export function setStorage(name: string, value: any | null | undefined) {
  if (!isBrowser()) {
    return
  }
  if (value === null || value === undefined) {
    Cookies.remove(name)
    localStorage.removeItem(name)
    return
  }
  Cookies.set(name, value)
  localStorage.setItem(name, value)
}

export function delStorage(name: string) {
  if (!isBrowser()) {
    return
  }
  Cookies.remove(name)
  localStorage.removeItem(name)
}

export function getStorage(name: string): string {
  if (!isBrowser()) {
    return ''
  }
  return Cookies.get(name) || localStorage.getItem(name) || ''
}

const redirectAfterLoginKey = 'redirectAfterLogin'

export function getRedirectAfterLogin() {
  return getStorage(redirectAfterLoginKey)
}

export function setRedirectAfterLogin(redirect: string | string[] | undefined) {
  if (Array.isArray(redirect)) {
    setStorage(redirectAfterLoginKey, redirect[0])
    return
  }
  setStorage(redirectAfterLoginKey, redirect)
}

export function getLocalStorage() {
  return typeof localStorage === 'undefined' ? undefined : localStorage
}

class Storage {
  get clientID(): string {
    const key = 'clientID'
    let clientID = sessionStorage.getItem(key)
    if (clientID === null) {
      clientID = randomAlphanums(16)
      sessionStorage.setItem(key, clientID)
    }
    return clientID
  }

  get umi_locale(): string {
    return getLocalStorage()?.getItem('umi_locale') as string
  }

  set umi_locale(value: string) {
    getLocalStorage()?.setItem('umi_locale', value)
  }

  get fileTreeWidth(): number {
    return +(getLocalStorage()?.getItem('fileTreeWidth') || 370)
  }

  set fileTreeWidth(width: number) {
    getLocalStorage()?.setItem('fileTreeWidth', String(width))
  }

  get cardBodyWidth(): number {
    return +(getLocalStorage()?.getItem('card-body-width') || 1000)
  }

  set cardBodyWidth(width: number) {
    getLocalStorage()?.setItem('card-body-width', String(width))
  }

  get ganttSiderWidth(): number {
    return +(getLocalStorage()?.getItem('ganttSiderWidth') || 400)
  }

  set ganttSiderWidth(width: number) {
    getLocalStorage()?.setItem('ganttSiderWidth', String(width))
  }

  getObject(key: string, defaultValue = {}) {
    try {
      return JSON.parse(getLocalStorage()?.getItem(key) || '{}')
    } catch {
      return defaultValue
    }
  }

  updateObject(key: string, values: any) {
    if (isEmpty(values)) {
      getLocalStorage()?.removeItem(key)
    } else {
      getLocalStorage()?.setItem(key, JSON.stringify({ ...this.getObject(key), ...values }))
    }
  }

  get expandedTestGroups(): string[] | undefined {
    try {
      return JSON.parse(getLocalStorage()?.getItem('expandedTestGroups') || '[]')
    } catch {
      return undefined
    }
  }

  set expandedTestGroups(value: string[] | undefined) {
    getLocalStorage()?.setItem('expandedTestGroups', JSON.stringify(value))
  }

  get sharedFilePath(): string {
    return getLocalStorage()?.getItem('shared_file_path') || ''
  }

  set sharedFilePath(path: string) {
    getLocalStorage()?.setItem('shared_file_path', path)
  }

  set createCardDefaultList(value: string) {
    getLocalStorage()?.setItem('create_card_default_list', value)
  }

  get createCardDefaultList(): string {
    return getLocalStorage()?.getItem('create_card_default_list') || ''
  }

  get columnWidth(): Record<string, number> | undefined {
    try {
      return JSON.parse(getLocalStorage()?.getItem('columnWidth') || '{}')
    } catch {
      return undefined
    }
  }

  set columnWidth(value: Record<string, number> | undefined) {
    getLocalStorage()?.setItem('columnWidth', JSON.stringify(value))
  }

  get docTocFixState(): boolean {
    return JSON.parse(getLocalStorage()?.getItem('doc_toc_fixed') || 'true')
  }

  set docTocFixState(value: boolean) {
    getLocalStorage()?.setItem('doc_toc_fixed', JSON.stringify(value))
  }

  getKanbanTitleWidth(id: string): string {
    return getLocalStorage()?.getItem(`${id}:kanban_title_width`) as string
  }

  setKanbanTitleWidth(id: string, width: string) {
    getLocalStorage()?.setItem(`${id}:kanban_title_width`, width)
  }

  get formColumns(): Record<string, object> | undefined {
    try {
      return JSON.parse(getLocalStorage()?.getItem('from-columns') || '{}')
    } catch {
      return undefined
    }
  }

  set formColumns(value: Record<string, object> | undefined) {
    if (value === undefined) {
      getLocalStorage()?.removeItem('from-columns')
    } else {
      getLocalStorage()?.setItem('from-columns', JSON.stringify(value))
    }
  }

  getFileView(): Record<string, any> {
    return this.getObject('fileView', {})
  }
}

const storage = new Storage()

export default storage
