import _ from 'lodash'

import storage, { getStorage } from '@/utils/storage'

export const tokenGet = () => getStorage('xiemala_token')
export const bearerGet = () => _.trimStart(tokenGet(), 'Bearer ')
export const authorization = (token?: string | null) => (token || tokenGet()).slice(16)
export const protoMeta = (token?: string | null, clientID?: string | null) => ({
  authorization: authorization(token),
  language: storage.umi_locale || 'zh-CN',
  'client-id': clientID != null ? clientID : storage.clientID,
  'device-type': 'web',
})
